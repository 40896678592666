<template>
  <div>
    <TopNavBar />
    <h1>Hello</h1>
    <img alt="Vue logo" src="/assets/logo.png">

    <BottomNavBar />
  </div>
</template>

<script>
import TopNavBar from "@/components/TopNavBar";
import BottomNavBar from "@/components/BottomNavBar";
export default {
  name: 'Home',
  components: {TopNavBar, BottomNavBar},
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
